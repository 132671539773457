define("discourse/plugins/discourse-assign/discourse/controllers/group-assigned", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _service, _ajax, _environment, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GroupAssigned = _exports.default = (_dec = (0, _decorators.default)("router.currentRoute.queryParams.order"), _dec2 = (0, _decorators.default)("router.currentRoute.queryParams.ascending"), _dec3 = (0, _decorators.default)("router.currentRoute.queryParams.search"), _dec4 = (0, _decorators.default)("site.mobileView"), (_class = class GroupAssigned extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "application", _descriptor2, this);
      _defineProperty(this, "loading", false);
      _defineProperty(this, "offset", 0);
      _defineProperty(this, "filterName", "");
      _defineProperty(this, "filter", "");
    }
    order(order) {
      return order || "";
    }
    ascending(ascending) {
      return ascending || false;
    }
    search(search) {
      return search || "";
    }
    isDesktop(mobileView) {
      return !mobileView;
    }
    _setFilter(filter) {
      this.set("loading", true);
      this.set("offset", 0);
      this.set("filter", filter);
      const groupName = this.group.name;
      (0, _ajax.ajax)(`/assign/members/${groupName}`, {
        type: "GET",
        data: {
          filter: this.filter,
          offset: this.offset
        }
      }).then(result => {
        if (this.router.currentRoute.params.filter !== "everyone") {
          this.router.transitionTo("group.assigned.show", groupName, "everyone");
        }
        this.set("members", result.members);
      }).finally(() => {
        this.set("loading", false);
      });
    }
    findMembers(refresh) {
      if (refresh) {
        this.set("members", this.model.members);
        return;
      }
      if (this.loading || !this.model) {
        return;
      }
      if (this.model.members.length >= this.offset + 50) {
        this.set("loading", true);
        this.set("offset", this.offset + 50);
        (0, _ajax.ajax)(`/assign/members/${this.group.name}`, {
          type: "GET",
          data: {
            filter: this.filter,
            offset: this.offset
          }
        }).then(result => {
          this.members.pushObjects(result.members);
        }).finally(() => this.set("loading", false));
      }
    }
    loadMore() {
      this.findMembers();
    }
    onChangeFilterName(value) {
      (0, _debounce.default)(this, this._setFilter, value, _environment.INPUT_DELAY * 2);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "application", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "order", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "order"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "ascending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDesktop", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isDesktop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterName"), _class.prototype)), _class));
});