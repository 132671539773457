define("discourse/plugins/discourse-assign/discourse/components/modal/assign-user", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember-compat/tracked-built-ins", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _trackedBuiltIns, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="assign" @title={{this.title}} @closeModal={{@closeModal}}>
    <:body>
      <AssignUserForm
        @model={{this.model}}
        @onSubmit={{this.onSubmit}}
        @formApi={{this.formApi}}
      />
    </:body>
  
    <:footer>
      <DButton
        class="btn-primary"
        @action={{this.formApi.submit}}
        @label={{if
          this.model.reassign
          "discourse_assign.reassign.title"
          "discourse_assign.assign_modal.assign"
        }}
      />
  
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "wlibZ2f0",
    "block": "[[[8,[39,0],[[24,0,\"assign\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@model\",\"@onSubmit\",\"@formApi\"],[[30,0,[\"model\"]],[30,0,[\"onSubmit\"]],[30,0,[\"formApi\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"formApi\",\"submit\"]],[52,[30,0,[\"model\",\"reassign\"]],\"discourse_assign.reassign.title\",\"discourse_assign.assign_modal.assign\"]]],null],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"assign-user-form\",\"d-button\",\"if\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/components/modal/assign-user.hbs",
    "isStrictMode": false
  });
  let AssignUser = _exports.default = (_class = class AssignUser extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _defineProperty(this, "model", new _trackedBuiltIns.TrackedObject(this.args.model));
      // `submit` property will be mutated by the `AssignUserForm` component
      _defineProperty(this, "formApi", {
        submit() {}
      });
    }
    get title() {
      let i18nSuffix;
      switch (this.model.targetType) {
        case "Post":
          i18nSuffix = "_post_modal";
          break;
        case "Topic":
          i18nSuffix = "_modal";
          break;
      }
      return _I18n.default.t(`discourse_assign.assign${i18nSuffix}.${this.model.reassign ? "reassign_title" : "title"}`);
    }
    async onSubmit() {
      this.args.closeModal();
      await this.taskActions.assign(this.model);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AssignUser);
});