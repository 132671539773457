define("discourse/plugins/discourse-assign/discourse/initializers/assign-user-menu", ["exports", "@ember/template", "discourse/lib/plugin-api", "discourse/lib/text", "I18n", "discourse/plugins/discourse-assign/discourse/components/user-menu/assigns-list"], function (_exports, _template, _pluginApi, _text, _I18n, _assignsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _exports.default = {
    name: "assign-user-menu",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (!siteSettings.assign_enabled) {
          return;
        }
        const currentUser = api.getCurrentUser();
        if (!currentUser?.can_assign) {
          return;
        }
        if (api.registerNotificationTypeRenderer) {
          api.registerNotificationTypeRenderer("assigned", NotificationItemBase => {
            return class extends NotificationItemBase {
              get linkTitle() {
                if (this.isGroup()) {
                  return _I18n.default.t(`user.assigned_to_group.${this.postOrTopic()}`, {
                    group_name: this.notification.data.display_username
                  });
                }
                return _I18n.default.t(`user.assigned_to_you.${this.postOrTopic()}`);
              }
              get icon() {
                return this.isGroup() ? "group-plus" : "user-plus";
              }
              get label() {
                if (!this.isGroup()) {
                  return "";
                }
                return this.notification.data.display_username;
              }
              get description() {
                return (0, _template.htmlSafe)((0, _text.emojiUnescape)(_I18n.default.t(`user.assignment_description.${this.postOrTopic()}`, {
                  topic_title: this.notification.fancy_title,
                  post_number: this.notification.post_number
                })));
              }
              isGroup() {
                return this.notification.data.message === "discourse_assign.assign_group_notification";
              }
              postOrTopic() {
                return this.notification.post_number === 1 ? "topic" : "post";
              }
            };
          });
        }
        if (api.registerUserMenuTab) {
          api.registerUserMenuTab(UserMenuTab => {
            return class extends UserMenuTab {
              constructor() {
                super(...arguments);
                _defineProperty(this, "id", "assign-list");
                _defineProperty(this, "panelComponent", _assignsList.default);
                _defineProperty(this, "icon", "user-plus");
                _defineProperty(this, "notificationTypes", ["assigned"]);
              }
              get count() {
                return this.getUnreadCountForType("assigned");
              }
              get linkWhenActive() {
                return `${this.currentUser.path}/activity/assigned`;
              }
            };
          });
        }
      });
    }
  };
});